<template>
  <div class="flex flex-col justify-start p-0 w-full">
    <div style="display: flex; justify-content: space-between; padding: 8px">
      <div class="flex items-center w-full">
        <div class="titulo mr-8">Pedidos</div>
        <label>Setor: </label>
        <select v-model="selectedSetor" style="padding: 2px; width: 100px">
          <option
              v-for="setor in setores"
              :key="setor.setorId"
              :value="setor.setorId"
          >
            {{ setor.nome }}
          </option>
        </select>
        <span class="flex-1"></span>
        <div style="display: flex;flex-direction: row; gap: 10px">
          <span>          <font-awesome-icon icon="user" size="lg"/>

{{ nomeUsuario }}
          </span>
          <button class="bg-red-500 hover:bg-red-800 text-white py-1 px-4 rounded-md" @click="logout">Sair</button>
        </div>

      </div>
    </div>

    <div class="flex flex-row justify-end p-1">
      <button
          title="Adicionar Novo Pedido"
          class="bg-blue-800 hover:bg-blue-900 text-white py-1 px-4 rounded-md"
          @click="openAddPedidoModal"
      >
        + Adicionar Pedido
      </button>
    </div>

    <transition-group name="fade" tag="div" class="cards">
      <div
          class="card w-full md:w-[350px]"
          v-for="pedido in filteredPedidos"
          :key="pedido.id"
      >
        <div class="card-content">
          <div class="row">
            <span class="label">Mesa/Conta</span>
            <span>{{ pedido.mesa }} / #{{ pedido.contaId }}</span>
          </div>
          <div class="row">
            <span class="label">Cliente</span>
            <span>{{ pedido.cliente }}</span>
          </div>
          <div class="row">
            <span class="label">Produto</span>
            <span>{{ pedido.produto }}</span>
            <span style="color: red">{{ pedido.observacao }}</span>
          </div>
          <div class="flex gap-4">
            <div class="row">
              <span class="label">Quantidade</span>
              <span>{{ pedido.quantidade }}</span>
            </div>
            <div class="row">
              <span class="label">Valor</span>
              <span>R$ {{ pedido.valor }}</span>
            </div>
          </div>
        </div>
        <div class="card-buttons">
          <button
              class="button-status"
              :class="pedido.status === 'PENDENTE' ? 'laranja' : ''"
              @click="() => handleButtonStatusClick(pedido, 'PENDENTE')"
          >
            PENDENTE
          </button>
          <button
              class="button-status"
              :class="pedido.status === 'EM_PREPARO' ? 'marrom' : ''"
              @click="() => handleButtonStatusClick(pedido, 'EM_PREPARO')"
          >
            EM PREPARO
          </button>
          <button
              class="button-status"
              :class="pedido.status === 'PRONTO' ? 'verde' : ''"
              @click="() => handleButtonStatusClick(pedido, 'PRONTO')"
          >
            PRONTO
          </button>
          <button
              class="button-status"
              :class="pedido.status === 'ENTREGUE' ? 'azul' : ''"
              @click="() => handleButtonStatusClick(pedido, 'ENTREGUE')"
          >
            ENTREGUE
          </button>
          <button
              class="button-status"
              :class="pedido.status === 'CANCELADO' ? 'vermelho' : ''"
              @click="() => handleButtonStatusClick(pedido, 'CANCELADO')"
          >
            CANCELADO
          </button>
        </div>
      </div>
    </transition-group>
  </div>

  <!-- Modal para adicionar um novo pedido -->
  <div v-if="showAddPedidoModal" class="modal-overlay">
    <div class="modal-content">
      <h3 class="font-bold mb-4">Adicionar Novo Pedido</h3>
      <div class="flex flex-col">
        <label class="text-sm">Mesa/Conta:</label>
        <select
            v-model="newPedido.contaId"
            class="border-2 rounded-md p-2 mb-1"
        >
          <option value="" disabled selected>Selecione uma mesa</option>
          <option
              v-for="mesa in mesas"
              :key="mesa.contaId"
              :value="mesa.contaId"
          >
            {{ mesa.numero }} / #{{ mesa.contaId }}
          </option>
        </select>
      </div>
      <div class="flex flex-col">
        <label class="text-sm">Cliente:</label>
        <select
            v-model="newPedido.usuarioId"
            class="border-2 rounded-md p-2 mb-1"
        >
          <option value="" disabled selected>Selecione um garçom</option>
          <option
              v-for="garcom in garcons"
              :key="garcom.garcomId"
              :value="garcom.garcomId"
          >
            {{ garcom.nome }}
          </option>
        </select>
      </div>
      <div class="flex flex-col">
        <label class="text-sm">Produto:</label>
        <div class="flex items-center gap-2">
          <input
              type="text"
              v-model="selectedProdutoName"
              disabled
              class="border-2 rounded-md p-2 mb-1 flex-1"
              placeholder="Selecione um produto"
          />
          <button
              @click="openSearchProdutoModal"
              class="bg-blue-600 hover:bg-blue-700 text-white py-1 px-4 rounded-md"
          >
            ...
          </button>
        </div>
      </div>
      <div class="flex gap-2">
        <div class="flex flex-col flex-1">
          <label class="text-sm">Quantidade:</label>
          <input
              v-model="newPedido.quantidade"
              type="number"
              min="1"
              class="border-2 rounded-md p-2 mb-1"
          />
        </div>
      </div>
      <div class="flex flex-col">
        <label class="text-sm">Observação:</label>
        <textarea
            v-model="newPedido.observacao"
            class="border-2 rounded-md p-2 mb-2"
        ></textarea>
      </div>
      <div class="flex gap-2">
        <button
            @click="addPedido"
            class="bg-green-600 hover:bg-green-700 text-white py-1 px-4 rounded-md"
        >
          Salvar
        </button>
        <button
            @click="closeAddPedidoModal"
            class="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded-md"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>

  <!-- Modal de Pesquisa de Produtos -->
  <div v-if="showSearchProdutoModal" class="modal-overlay-search">
    <div class="modal-content">
      <h3 class="font-bold mb-4">Pesquisar Produto</h3>
      <div class="flex flex-col mb-4">
        <label class="text-sm">Filtrar por Nome:</label>
        <input
            v-model="searchProduto"
            type="text"
            class="border-2 rounded-md p-2"
            placeholder="Digite o nome do produto"
        />
      </div>
      <!-- Contêiner com barra de rolagem -->
      <div class="overflow-container">
        <table class="w-full border-collapse border border-gray-300">
          <thead>
          <tr>
            <th class="border border-gray-300 px-4 py-2">Nome</th>
            <th class="border border-gray-300 px-4 py-2">Descrição</th>
            <th class="border border-gray-300 px-4 py-2">Valor</th>
            <th class="border border-gray-300 px-4 py-2">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="produto in filteredProdutos"
              :key="produto.produtoId"
              class="hover:bg-gray-100"
          >
            <td class="border border-gray-300 px-4 py-2">
              {{ produto.nome }}
            </td>
            <td class="border border-gray-300 px-4 py-2">
              {{ produto.descricao }}
            </td>
            <td class="border border-gray-300 px-4 py-2">
              R$ {{ produto.preco.toFixed(2) }}
            </td>
            <td class="border border-gray-300 px-4 py-2">
              <button
                  @click="selectProduto(produto)"
                  class="bg-green-600 hover:bg-green-700 text-white py-1 px-4 rounded-md"
              >
                Selecionar
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end gap-2 mt-4">
        <button
            @click="closeSearchProdutoModal"
            class="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded-md"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>

  <!-- Modal de Justificativa para Cancelamento -->
  <div v-if="showCancelJustificationModal" class="modal-overlay">
    <div class="modal-content">
      <h3 class="font-bold mb-4">Justificativa de Cancelamento</h3>
      <textarea
          v-model="cancelJustification"
          class="border-2 rounded-md p-2 mb-4 w-full"
          rows="4"
          placeholder="Escreva a justificativa do cancelamento..."
      ></textarea>
      <div class="flex gap-2 justify-end">
        <button
            @click="confirmCancelPedido"
            class="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded-md"
        >
          Confirmar Cancelamento
        </button>
        <button
            @click="closeCancelJustificationModal"
            class="bg-gray-600 hover:bg-gray-700 text-white py-1 px-4 rounded-md"
        >
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {MesaService} from "@/services/MesaService";
import {PedidoService} from "@/services/PedidoService";
import {ProdutoService} from "@/services/ProdutoService";
import {UsuarioService} from "@/services/UsuarioService";
import {SetorService} from "@/services/SetorService";
import {useUserStore} from "@/store/userStore";
import {AuthService} from "@/services/AuthService";
import {ref} from "vue";

const servicePedido = new PedidoService();
const serviceMesa = new MesaService();
const usuarioService = new UsuarioService();
const serviceProduto = new ProdutoService();
const setorService = new SetorService();
const userStore = useUserStore();
const authService = new AuthService();
export default {

  props: {
    selectedMesa: Object,
  },
  async mounted() {
    const user = userStore.getUser();
    this.nomeUsuario = user.nome;
    this.mesas = await serviceMesa.findAllAbertas(user.estabelecimentoId);
    this.garcons = await usuarioService.findAllGarcons(user.estabelecimentoId);
    this.produtos = await serviceProduto.findAll(user.estabelecimentoId);
    this.pedidos = await servicePedido.findAll(user.estabelecimentoId);
    this.setores = await setorService.findAll();

    this.interval = setInterval(async () => {
      this.pedidos = await servicePedido.findAll(user.estabelecimentoId);
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  data() {
    return {
      nomeUsuario: "",
      interval: null,
      pedidos: [],
      mesas: [],
      setores: [],
      garcons: [],
      produtos: [],
      selectedSetor: 1,
      showAddPedidoModal: false,
      showSearchProdutoModal: false,
      searchProduto: "",
      selectedProdutoName: "",
      selectedProdutoId: null,
      newPedido: {
        contaId: "",
        usuarioId: "",
        produtoId: "",
        quantidade: 1,
        observacao: "",
      },
      showCancelJustificationModal: false,
      cancelJustification: "",
      selectedPedidoToCancel: null,
    };
  },
  computed: {
    filteredPedidos() {
      const statusOrder = {
        PENDENTE: 1,
        EM_PREPARO: 2,
        PRONTO: 3,
        ENTREGUE: 4,
        CANCELADO: 5,
      };

      const filtered = this.pedidos
          .filter(
              (pedido) =>
                  this.selectedSetor === 1 || pedido.setorId === this.selectedSetor
          )
          .filter(
              (pedido) =>
                  !this.selectedMesa || pedido.contaId === this.selectedMesa.contaId
          );

      return filtered.sort(
          (a, b) => statusOrder[a.status] - statusOrder[b.status]
      );
    },
    filteredProdutos() {
      return this.produtos.filter((produto) =>
          produto.nome.toLowerCase().includes(this.searchProduto.toLowerCase())
      );
    },
  },
  methods: {
    async logout() {
      await authService.APIlogout();
      await userStore.logout();
    },

    async openAddPedidoModal() {
      const user = userStore.getUser();
      this.mesas = await serviceMesa.findAllAbertas(user.estabelecimentoId);
      this.showAddPedidoModal = true;
    },
    closeAddPedidoModal() {
      this.showAddPedidoModal = false;
      this.resetNewPedidoForm();
    },
    resetNewPedidoForm() {
      this.newPedido = {
        contaId: "",
        usuarioId: "",
        produtoId: "",
        quantidade: 1,
        observacao: "",
      };
    },
    async addPedido() {
      if (
          this.newPedido.contaId &&
          this.newPedido.usuarioId &&
          this.newPedido.produtoId
      ) {
        const pedidoSalvo = await servicePedido.create(this.newPedido);
        this.pedidos.push(pedidoSalvo);
        this.closeAddPedidoModal();
      } else {
        alert("Por favor, preencha todos os campos obrigatórios!");
      }
    },
    openSearchProdutoModal() {
      this.showSearchProdutoModal = true;
    },
    closeSearchProdutoModal() {
      this.showSearchProdutoModal = false;
    },
    selectProduto(produto) {
      this.selectedProdutoName = produto.nome;
      this.newPedido.produtoId = produto.produtoId;
      this.closeSearchProdutoModal();
    },
    handleButtonStatusClick(pedido, status) {
      if (status === "CANCELADO") {
        this.openCancelJustificationModal(pedido);
        return; // Não executa o cancelamento diretamente
      }
      servicePedido.changeStatus(
          pedido.id,
          pedido.contaId,
          pedido.valor,
          status
      );
      this.pedidos = this.pedidos.map((ped) =>
          ped.id === pedido.id ? {...ped, status} : ped
      );
    },
    openCancelJustificationModal(pedido) {
      this.selectedPedidoToCancel = pedido;
      this.showCancelJustificationModal = true;
      this.cancelJustification = ""; // Limpa a justificativa anterior
    },
    closeCancelJustificationModal() {
      this.showCancelJustificationModal = false;
      this.selectedPedidoToCancel = null;
    },
    async confirmCancelPedido() {
      if (!this.cancelJustification.trim()) {
        alert("Por favor, escreva uma justificativa para o cancelamento!");
        return;
      }
      const {id, contaId, valor} = this.selectedPedidoToCancel;
      await servicePedido.changeStatus(
          id,
          contaId,
          valor,
          "CANCELADO",
          this.cancelJustification
      );
      this.pedidos = this.pedidos.map((ped) =>
          ped.id === id ? {...ped, status: "CANCELADO"} : ped
      );
      this.closeCancelJustificationModal();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 8px;
  width: 100%;
}

.cards {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgb(57, 57, 57);
  box-sizing: border-box;
}

.card {
  padding: 16px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 16px;
  background-color: #f3f2f0;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.card-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-left: auto;
}

.button-status {
  padding: 8px;
  width: 110px;
  border: none;
  border-radius: 16px;
  background-color: #e0e0e0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.button-status.active {
  background-color: #333;
  color: #fff;
}

.button-status:hover:not(.active) {
  background-color: #d3d3d3;
}

.row {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 12px;
  color: #888;
}

.titulo {
  font-size: 18px;
  font-weight: bold;
}

.laranja {
  background-color: rgb(226, 150, 27);
  color: white;
  font-weight: bold;
}

.verde {
  background-color: rgb(16, 119, 0);
  color: white;
  font-weight: bold;
}

.azul {
  background-color: rgba(4, 20, 97, 0.704);
  color: white;
  font-weight: bold;
}

.vermelho {
  background-color: rgb(191, 0, 0);
  color: white;
  font-weight: bold;
}

.marrom {
  background-color: rgb(150, 100, 0);
  color: white;
  font-weight: bold;
}

/* Transition animations */
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease; /* Duração de 1s */
}

.fade-enter-from {
  transform: translateY(-20px);
  opacity: 0;
}

.fade-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.fade-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.modal-overlay-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.overflow-container {
  max-height: 300px; /* Altura máxima do contêiner */
  overflow-y: auto; /* Habilitar barra de rolagem vertical */
  border: 1px solid #ccc; /* Opcional: borda ao redor da lista */
  margin-bottom: 16px; /* Espaço entre o contêiner e os botões */
}

/* Adicional: Personalizar a barra de rolagem */
.overflow-container::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

.overflow-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Cor do "polegar" da barra */
  border-radius: 4px; /* Arredondar a borda */
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background-color: #bbb; /* Cor ao passar o mouse */
}
</style>
