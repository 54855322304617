import axios from "@/utils/AxiosPag";
export class PedidoService {
    async findAll(estabelecimentoId) {
    const response = await axios.getInstance().get(`/pedidos/estabelecimento/${estabelecimentoId}`);
        return response.data;
    }

    async changeStatus(pedidoId, contaId, valor, status, justificativa = "") {
        return await axios.getInstance().put(`/pedidos/${pedidoId}/status`, {
            contaId,
            valor,
            status,
            justificativa,
        });
    }

    async create(pedido) {
        const response = await axios.getInstance().post(`/pedidos`, pedido);
        return response.data;
    }
}
